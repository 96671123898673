@use '@angular/material' as mat;

// regular style toast
@import 'ngx-toastr/toastr';

// Material theme
@include mat.core();
@import 'theme/primary-color';
@import 'theme/accent-color';
@import 'theme/warn-color';
@import '../../../styles/global-styles';

$mat-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: mat.m2-define-palette($primary-palette, 700, 300, 900),
        accent: mat.m2-define-palette($accent-palette, 300, 100, 500),
        warn: mat.m2-define-palette($warn-palette, 700),
      ),
    )
);
@include mat.all-component-themes($mat-theme);

html,
body {
  height: 100%;
}

// Cookie consent
#cc-main {
  --cc-btn-primary-color: var(--bucher-red) !important;
  --cc-btn-primary-bg: white !important;
  --cc-btn-primary-border-color: var(--bucher-red) !important;
  --cc-btn-primary-hover-bg: var(--bucher-red) !important;
  --cc-btn-primary-hover-border-color: var(--bucher-red) !important;
  --cc-btn-primary-hover-color: white !important;

  --cc-btn-secondary-color: var(--bucher-red) !important;
  --cc-btn-secondary-bg: white !important;
  --cc-btn-secondary-border-color: white !important;
  --cc-btn-secondary-hover-bg: var(--bucher-red) !important;
  --cc-btn-secondary-hover-border-color: var(--bucher-red) !important;
  --cc-btn-secondary-hover-color: white !important;
}

// Cookie consent banner title
#cc-main .cm__title {
  margin-left: 16px !important;
}

// Indicator for development environments
#dev-indicator {
  position: fixed;
  width: 100vw;
  height: 5px;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--bucher-mid-blue);
}

// Loading screen while app initialization
@keyframes app-init-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.app-initialization-place-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 75%;
    animation: app-init-pulse 3s infinite;
  }
}
